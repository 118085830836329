import axios from 'axios';
import { notify } from '../components/notify';
import Config from '../config/config';

export async function checkToken(token, callback) {
  axios({
    method: 'GET',
    url: Config.server + `recaptcha/${token}`,
  })
    .then((res) => {
      callback(res.data.valid);
    })
    .catch((err) => {
      callback(false);
      console.log(err.response);
      if (err.response?.data) {
        if (err.response.data?.message) {
          notify('error', err.response.data.message);
        } else {
          notify('error', 'something went wrong');
        }
      } else {
        notify('error', 'Error');
      }
    });
}
