import { Button } from '@mui/material';
import React from 'react';
import PlayButton from '../../play-button';

export default function SetupExtensionInfo({ data, onState }) {
    return (
        <div className="setup-extension-info-popup">
            <div className="texts">
                <p>
                    To continue, you should have the DID-SIOP Browser extension installed and
                    configured.
                </p>
                <p>
                    If you have already done that, please hit <b>Continue</b>. Otherwise, hit{' '}
                    <b>Cancel</b> and refer to the <b>Download SIOP Extension</b> section below to
                    download and set it up in your browser.
                </p>
                <p>
                    Please note. after hitting <b>Continue</b>, you should log in to the SIOP
                    Extension and approve the Login Request.
                </p>
            </div>

            <div className="buttons-wrap">
                <PlayButton onClick={() => onState(false)} settings={data} />
                <Button className="button bg-grey" onClick={() => onState(false)}>
                    Cancel
                </Button>
            </div>
        </div>
    );
}
