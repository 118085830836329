import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogActions, Typography } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

export default function ConfirmPopUp({
  state,
  onClose,
  onConfirm,
  title,
  children,
}) {
  const handleClose = () => {
    onClose(false);
  };

  const handleOnConfirm = () => {
    onConfirm(true);
  };

  return (
    <div className="popup">
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={state}>
        <BootstrapDialogTitle id="customized-dialog-title">
          {title}
        </BootstrapDialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <Typography>{children}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnConfirm}>YES</Button>
          <Button color="secondary" onClick={handleClose}>
            NO
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
