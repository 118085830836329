import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateSetting } from '../api/settings';
import loadable from '@loadable/component';
const SigningParams = loadable(() => import('../components/settings/op/signing-params'));
const RPDidResolver = loadable(() => import('../components/settings/rp/did-resolver'));
const OptionalParameters = loadable(() => import('../components/settings/rp/optional-parameters'));
const RequestInfo = loadable(() => import('../components/settings/rp/request-info'));
const RequestVp = loadable(() => import('../components/settings/rp/request-vp'));
const StaticMetadata = loadable(() => import('../components/settings/rp/static-metadata'));
const BackDrop = loadable(() => import('../layout/backdrop'));
const Navbar = loadable(() => import('../layout/navbar'));
const SideNavbar = loadable(() => import('../layout/side-navbar'));
const OPDidResolver = loadable(() => import('../components/settings/op/did-resolver'));
const VerifiablePresentation = loadable(() =>
    import('../components/settings/op/verifiable-presentation')
);

const initialTemplate = {
    rp: {
        request_info: {},
        did_resolver: [],
        optional_params: [],
        request_vp: {},
        static_metadata: {}
    },
    op: {
        signing_params: {},
        did_resolver: [],
        verifiable_presentation: {}
    }
};

export default function Settings() {
    const navigator = useNavigate();
    const location = useLocation();

    const [Active, setActive] = useState('rp/request_info');
    const [Menu, setMenu] = useState(true);
    const [BackDropState, setBackDropState] = useState(false);
    const [Settings, setSettings] = useState(initialTemplate);
    const [Name, setName] = useState('unknown');

    useEffect(() => {
        if (!location.state?.id || !location.state.name) {
            navigator('/');
        }

        if (location.state?.name) setName(location.state.name);

        if (location.state?.settings) {
            setSettings(location.state.settings);
        }
    }, [location.state]);

    function handleOnMenuClick() {
        setMenu(!Menu);
    }

    function MenuIcon() {
        return (
            <span className="icon-wrap">
                <FaBars />
            </span>
        );
    }

    function handleOnSettingsUpdate(data) {
        navigator('', {
            state: {
                ...location.state,
                settings: {
                    ...Settings,
                    [data.key]: data.value
                }
            }
        });

        setSettings({
            ...Settings,
            [data.key]: data.value
        });
    }

    function onSave() {
        setBackDropState(true);
        updateSetting(Settings, (status) => {
            setBackDropState(false);
        });
    }

    return (
        <div id="settings">
            <Navbar
                title={{
                    text: Name,
                    color: '#25C3B5',
                    back: { path: '/', state: {} }
                }}
                button={{
                    children: MenuIcon(),
                    icon: true,
                    onClick: handleOnMenuClick
                }}
            />

            <div className="content-wrap">
                <SideNavbar setActive={setActive} Active={Active} Menu={Menu} setMenu={setMenu} />

                <div className="content-container">
                    {/* rp settings */}
                    <RequestInfo
                        active={Active === 'rp/request_info'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    <RPDidResolver
                        active={Active === 'rp/did_resolver'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    <OptionalParameters
                        active={Active === 'rp/optional_params'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    <RequestVp
                        active={Active === 'rp/request_vp'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    <StaticMetadata
                        active={Active === 'rp/static_metadata'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    {/* op settings */}
                    <SigningParams
                        active={Active === 'op/signing_params'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    <OPDidResolver
                        active={Active === 'op/did_resolver'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                    <VerifiablePresentation
                        active={Active === 'op/verifiable_presentation'}
                        settings={Settings}
                        callback={handleOnSettingsUpdate}
                    />
                </div>
            </div>

            <div className="bottom-content">
                <Button className="button bg-themed" variant="contained" onClick={onSave}>
                    Save and Proceed
                </Button>
            </div>

            <BackDrop open={BackDropState} />
        </div>
    );
}
