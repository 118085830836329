import { Button, FormControl, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { checkToken } from '../../../api/recaptcha';
import { createSetting, duplicateSetting, updateSetting } from '../../../api/settings';
import config from '../../../config/config';
import { notify } from '../../notify';

export default function Settings({ data, onReset, onUpdate, onState }) {
    const BtnRef = useRef();
    const ReCAPTCHARef = useRef();
    const navigator = useNavigate();

    useEffect(() => {
        if (data?._id && data?.name) {
            let name = document.getElementById('settings-name');
            if (name) name.value = data.name;
        }
    }, [data]);

    async function onNext() {
        let name = document.getElementById('settings-name');

        if (!name.value) return notify('warning', 'Settings name is required');

        if (BtnRef.current) BtnRef.current.disabled = true;

        /* get token from google recaptcha */
        let token = await ReCAPTCHARef.current.executeAsync();

        checkToken(token, (valid) => {
            if (BtnRef.current) {
                BtnRef.current.disabled = true;
            }

            if (!valid) {
                if (BtnRef.current) BtnRef.current.disabled = false;
                return notify('warning', 'Recaptcha failed');
            }

            /* if there is a given id and not onUpdate duplicate the settings */
            if (data?._id && !onUpdate) {
                const template = {
                    rp: {
                        request_info: {},
                        did_resolver: [],
                        optional_params: [],
                        request_vp: {},
                        static_metadata: {}
                    },
                    op: {
                        signing_params: {},
                        did_resolver: [],
                        verifiable_presentation: {}
                    }
                };

                duplicateSetting(
                    {
                        id: data._id,
                        name: name.value
                    },
                    (data) => {
                        if (data?.status === 'success') {
                            onReset(); // reset the id value

                            let item = data.data;

                            for (let key in template) {
                                if (item[key]) {
                                    template[key] = {
                                        ...template[key],
                                        ...item[key]
                                    };
                                }
                            }

                            navigator('/settings', {
                                state: {
                                    id: data.data._id,
                                    name: name.value,
                                    settings: {
                                        id: data.data._id,
                                        name: name.value,
                                        ...template
                                    }
                                }
                            });
                        }

                        if (BtnRef.current) BtnRef.current.disabled = false;
                    }
                );
            } else if (data?._id && onUpdate) {
                /* if there is a given id and onUpdate update the setting name */
                updateSetting(
                    {
                        id: data?._id,
                        name: name.value
                    },
                    (status) => {
                        if (status === 'success') {
                            onState(false, true);
                        }

                        if (BtnRef.current) BtnRef.current.disabled = false;
                    }
                );
            } else {
                /* if there is no given id and not onUpdate create the setting */
                const template = {
                    rp: {
                        request_info: {},
                        did_resolver: [],
                        optional_params: [],
                        request_vp: {},
                        static_metadata: {}
                    },
                    op: {
                        signing_params: {},
                        did_resolver: [],
                        verifiable_presentation: {}
                    }
                };

                createSetting(
                    {
                        name: name.value
                    },
                    (data) => {
                        if (data?.status === 'success') {
                            navigator('/settings', {
                                state: {
                                    id: data.data._id,
                                    name: name.value,
                                    settings: {
                                        id: data.data._id,
                                        name: name.value,
                                        ...template
                                    }
                                }
                            });
                        }

                        if (BtnRef.current) BtnRef.current.disabled = false;
                    }
                );
            }
        });
    }

    return (
        <div id="settings-popup">
            <FormControl fullWidth className="form">
                <TextField
                    id="settings-name"
                    fullWidth
                    placeholder="Setting Name"
                    size="small"
                    className="input dark"
                    required
                />

                <Button
                    className="button bg-themed limit-width"
                    variant="contained"
                    onClick={onNext}
                    ref={BtnRef}>
                    {onUpdate ? 'Save' : 'Next'}
                </Button>
            </FormControl>

            <ReCAPTCHA sitekey={config.recaptcha_key} size="invisible" ref={ReCAPTCHARef} />
        </div>
    );
}
