import './styles/app.scss';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Settings from './pages/settings';
import NotificationContainer from './components/notify';
import { Button } from '@mui/material';
import config from './config/config';

function App() {
    return (
        <div className="app">
            <p id="main-title" className="white">
                {' '}
                <span className="theme">SIOP</span> v2 Playground{' '}
            </p>

            <main className="main-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/settings" element={<Settings />} />
                </Routes>
            </main>

            <div className="bottom-container">
                <span className="note">Download SIOP Extension</span>
                <Button
                    download
                    href={`${config.demo}other/siop-did-extension.zip`}
                    size="small"
                    className="button bg-themed">
                    Download
                </Button>
            </div>

            <NotificationContainer />
        </div>
    );
}

export default App;
