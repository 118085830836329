import { Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

export default function Navbar({ title, button }) {
  const [TitleProps, setTitleProps] = useState({
    active: true,
    color: '#5E6F81',
    text: '',
    back: { path: '', color: '' },
  });
  const [ButtonProps, setButtonProps] = useState({
    active: true,
    styles: { color: '#5E6F81' },
    children: '',
    href: '',
    onClick: null,
    icon: false,
  });
  const navigate = useNavigate();

  function handleOnTitleClick() {
    if (TitleProps.back?.path) {
      navigate(TitleProps.back.path, {
        state: TitleProps.back?.state ?? {},
      });
    }
  }

  useEffect(() => {
    let isMounted = true;
    const title_props = {
      active: true,
      color: '#5E6F81',
      text: '',
      back: { path: '', color: '' },
    };
    const button_props = {
      active: true,
      styles: { color: '#5E6F81' },
      children: '',
      href: '',
      onClick: null,
      icon: false,
    };

    for (let item in title) {
      if (title[item]) title_props[item] = title[item];
    }

    for (let item in button) {
      if (button[item]) button_props[item] = button[item];
    }

    if (isMounted) setTitleProps(title_props);
    if (isMounted) setButtonProps(button_props);

    return () => {
      isMounted = false;
    };
  }, [title, button]);

  return (
    <div className="navbar">
      <div className="section left" data-active={TitleProps.active}>
        <h4
          style={{ color: TitleProps.color }}
          onClick={handleOnTitleClick}
          data-clickable={!!TitleProps.back?.path}
          className="title-wrap"
        >
          {TitleProps.back?.path && (
            <span
              style={{ color: TitleProps.back.color ?? TitleProps.color }}
              className="title-back"
            >
              <IoIosArrowBack />
            </span>
          )}

          {TitleProps.text}
        </h4>
      </div>
      <div className="section right">
        {ButtonProps.icon ? (
          <IconButton
            style={ButtonProps.styles}
            href={ButtonProps.href}
            onClick={ButtonProps.onClick}
          >
            {ButtonProps.children}
          </IconButton>
        ) : (
          <Button
            style={ButtonProps.styles}
            href={ButtonProps.href}
            onClick={ButtonProps.onClick}
          >
            {ButtonProps.children}
          </Button>
        )}
      </div>
    </div>
  );
}
