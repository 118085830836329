import axios from 'axios';
import { notify } from '../components/notify';
import Config from '../config/config';

export async function createSetting(data, callback) {
    axios({
        method: 'POST',
        url: Config.server + `settings/save`,
        data: data
    })
        .then((res) => {
            callback(res.data);
            notify(res.data.status, res.data.message);
        })
        .catch((err) => {
            callback(null);
            console.log(err.response);
            if (err.response?.data) {
                if (err.response.data?.message) {
                    notify('error', err.response.data.message);
                } else {
                    notify('error', 'something went wrong');
                }
            } else {
                notify('error', 'Error');
            }
        });
}

export async function duplicateSetting(data, callback) {
    axios({
        method: 'POST',
        url: Config.server + `settings/duplicate`,
        data: data
    })
        .then((res) => {
            callback(res.data);
            notify(res.data.status, res.data.message);
        })
        .catch((err) => {
            callback(null);
            console.log(err.response);
            if (err.response?.data) {
                if (err.response.data?.message) {
                    notify('error', err.response.data.message);
                } else {
                    notify('error', 'something went wrong');
                }
            } else {
                notify('error', 'Error');
            }
        });
}

export async function updateSetting(data, callback) {
    axios({
        method: 'PATCH',
        url: Config.server + `settings/update`,
        data: data
    })
        .then((res) => {
            callback(res.data.status);
            notify(res.data.status, res.data.message);
        })
        .catch((err) => {
            callback(null);
            console.log(err.response);
            if (err.response?.data) {
                if (err.response.data?.message) {
                    notify('error', err.response.data.message);
                } else {
                    notify('error', 'something went wrong');
                }
            } else {
                notify('error', 'Error');
            }
        });
}

export async function getSettings(callback) {
    axios({
        method: 'GET',
        url: Config.server + `settings`
    })
        .then((res) => {
            callback(res.data.data);
        })
        .catch((err) => {
            callback(null);
            console.log(err.response);
            if (err.response?.data) {
                if (err.response.data?.message) {
                    notify('error', err.response.data.message);
                } else {
                    notify('error', 'something went wrong');
                }
            } else {
                notify('error', 'Error');
            }
        });
}

export async function deleteSetting(id, callback) {
    axios({
        method: 'DELETE',
        url: Config.server + `settings/delete`,
        data: { id }
    })
        .then((res) => {
            callback(res.data);
            notify(res.data.status, res.data.message);
        })
        .catch((err) => {
            callback(null);
            console.log(err.response);
            if (err.response?.data) {
                if (err.response.data?.message) {
                    notify('error', err.response.data.message);
                } else {
                    notify('error', 'something went wrong');
                }
            } else {
                notify('error', 'Error');
            }
        });
}
