const { NODE_ENV, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_SERVER, REACT_APP_DEMO_SITE } =
    process.env;

module.exports = {
    node_env: NODE_ENV,
    recaptcha_key: REACT_APP_RECAPTCHA_SITE_KEY,
    demo: REACT_APP_DEMO_SITE
        ? REACT_APP_DEMO_SITE.slice(-1) === '/'
            ? REACT_APP_DEMO_SITE
            : REACT_APP_DEMO_SITE + '/'
        : null,
    server: REACT_APP_SERVER
        ? REACT_APP_SERVER.slice(-1) === '/'
            ? REACT_APP_SERVER
            : REACT_APP_SERVER + '/'
        : null
};
