import React from 'react';
import { CustomDidResolver } from '../utils/custom-did-resolver';
import { Button } from '@mui/material';
import { notify } from './notify';

export default function PlayButton({ onClick, settings }) {
    const [siopData, setSiopData] = React.useState({
        active: false,
        request: 'pre-generated-request'
    });

    React.useEffect(() => {
        generateRequestUri();
    }, [settings]);

    function getIdTokens(string) {
        if (!string) return [];

        return string.split(',').map((_) => _.trim());
    }

    async function generateRequestUri() {
        if (!settings?.rp?.request_info?.private_key || !settings?.rp?.request_info?.rp_did) return;

        let data = {
            redirect_uri: settings?.rp?.request_info?.rp_redirect_url,
            did: settings?.rp?.request_info?.rp_did,
            private_key: settings?.rp?.request_info?.private_key,
            registration: {
                jwks_uri: settings?.rp?.request_info?.id_url,
                id_token_signed_response_alg: getIdTokens(settings?.rp?.request_info?.id_token)
            },
            claims: settings.rp.request_vp,
            op_params: settings?.rp?.optional_params?.reduce(
                (obj, item) => Object.assign(obj, { [item.selected]: item.value }),
                {}
            )
        };

        const SIOP = await import('did-siop');
        const resolver = new SIOP.Resolvers.CombinedDidResolver('key');
        const customResolver = new CustomDidResolver();
        resolver.removeAllResolvers();
        resolver.addResolver(customResolver);

        let siopRp = await SIOP.RP.getRP(
            data.redirect_uri, // redirect_uri
            data.did, // did
            data.registration, // registration
            undefined,
            [resolver],
            data.op_params
        );

        siopRp.addSigningParams(data.private_key);

        let request = await siopRp.generateRequest({
            response_mode: 'tab-url',
            claims: data.claims
        });

        /* to extension to detect the login button */
        localStorage.setItem('new-content', 'true');

        if (request) {
            setSiopData({ active: true, request });
        }
    }

    function handleOnClick() {
        if (onClick) onClick();

        return notify('success', 'Request sent to DID-SIOP Extension');
    }

    return (
        <Button
            onClick={handleOnClick}
            data-active={siopData.active}
            data-did-siop={siopData.request}
            size="small"
            className="button bg-themed">
            Continue
        </Button>
    );
}
