import React, { useState } from 'react';
import Navbar from '../layout/navbar';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import PopUp from '../components/popup/popup';
import Settings from '../components/popup/containers/settings';
import { IconButton, Button } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegCopy } from 'react-icons/fa';
import { useEffect } from 'react';
import { deleteSetting, getSettings } from '../api/settings';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import ConfirmPopUp from '../components/popup/confirm-popup';
import PlayButton from '../components/play-button';
import SetupExtensionInfo from '../components/popup/containers/siop-extension-info';

export default function Home() {
    const [SettingsDeleteState, setSettingsDeleteState] = useState(null);
    const [SettingsState, setSettingsState] = useState(false);
    const [EditNameState, setEditNameState] = useState(false);
    const [ExtensionPopupState, setExtensionPopupState] = useState(false);
    const [Selected, setSelected] = useState(null);
    const [Data, setData] = useState([]);
    const navigator = useNavigate();

    useEffect(() => {
        fetchSettings();

        localStorage.setItem('new-content', true);
    }, []);

    function fetchSettings() {
        getSettings((data) => {
            if (data) setData(data);
        });
    }

    function onNavbarButtonClick() {
        setSettingsState(true);
    }

    function NavbarButton() {
        return (
            <span className="navbar-button">
                <BsFillPlusCircleFill /> Add Settings Template
            </span>
        );
    }

    function handleOnSettingsState(state, update) {
        setSettingsState(state);

        if (!state) {
            setSelected(null);
            setEditNameState(false);
        }

        if (update) fetchSettings();
    }

    function handleOnEdit(item) {
        const template = {
            rp: {
                request_info: {},
                did_resolver: [],
                optional_params: [],
                request_vp: {},
                static_metadata: {}
            },
            op: {
                signing_params: {},
                did_resolver: [],
                verifiable_presentation: {}
            }
        };

        for (let key in template) {
            if (item[key]) {
                template[key] = {
                    ...template[key],
                    ...item[key]
                };
            }
        }

        navigator('/settings', {
            state: {
                id: item._id,
                name: item.name,
                settings: {
                    id: item._id,
                    name: item.name,
                    ...template
                }
            }
        });
    }

    function handleOnDelete() {
        deleteSetting(SettingsDeleteState, (data) => {
            if (data?.status === 'success') {
                setData([...Data.filter((i) => i._id !== SettingsDeleteState)]);
                setSettingsDeleteState(null);
            }
        });
    }

    function handleOnCopy(id) {
        setSelected({ _id: id });

        handleOnSettingsState(true);
    }

    function handleOnEditNameState(state, item) {
        setSelected(item);

        setEditNameState(state);
        handleOnSettingsState(state);
    }

    function handleOnExtensionPopupState(state, item = null) {
        setSelected(item);

        setExtensionPopupState(state);
    }

    function toBase64(obj) {
        if (!obj) return 'pre_generated_settings';

        let objJsonStr = JSON.stringify(obj);
        let objJsonB64 = Buffer.from(objJsonStr).toString('base64');

        return objJsonB64;
    }

    return (
        <div id="home">
            <Navbar
                title={{ text: 'Saved SIOP Settings Templates' }}
                button={{
                    children: NavbarButton(),
                    styles: { color: '#25C3B5' },
                    onClick: onNavbarButtonClick
                }}
            />

            <div className="settings">
                {!Data.length ? (
                    <p className="grey-1">No settings</p>
                ) : (
                    Data.map((item) => {
                        return (
                            <div key={item._id} className="setting">
                                <div className="section">
                                    <p
                                        className="text grey-1"
                                        onClick={() => handleOnEditNameState(true, item)}>
                                        {item.name}
                                    </p>
                                </div>

                                <div className="section">
                                    <IconButton
                                        className="icon theme"
                                        onClick={() => handleOnEdit(item)}>
                                        <BiEdit />
                                    </IconButton>
                                    <IconButton
                                        className="icon theme"
                                        onClick={() => handleOnCopy(item._id)}>
                                        <FaRegCopy />
                                    </IconButton>
                                    <IconButton
                                        className="icon red"
                                        onClick={() => setSettingsDeleteState(item._id)}>
                                        <MdDeleteOutline />
                                    </IconButton>
                                    <Button
                                        onClick={() => handleOnExtensionPopupState(true, item)}
                                        size="small"
                                        className="button bg-themed">
                                        Play
                                    </Button>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>

            {/* this button is used to communicate with the extension */}
            <button
                id="did-siop-setting-button"
                data-did-siop-settings="pre-generated-settings"></button>

            <ConfirmPopUp
                title="Confirm Deletion"
                onConfirm={handleOnDelete}
                state={Boolean(SettingsDeleteState)}
                onClose={() => setSettingsDeleteState(null)}>
                This action cannot be reversed. Are you sure ?
            </ConfirmPopUp>
            <PopUp
                title="Setting Template Name"
                state={SettingsState}
                onClose={() => handleOnSettingsState(false)}>
                <Settings
                    data={Selected}
                    onReset={() => setSelected(null)}
                    onUpdate={EditNameState}
                    onState={(state, update) => handleOnSettingsState(state, update)}
                />
            </PopUp>

            <PopUp
                title="SIOP Extension"
                state={ExtensionPopupState}
                onClose={() => handleOnExtensionPopupState(false)}>
                <SetupExtensionInfo data={Selected} onState={handleOnExtensionPopupState} />
            </PopUp>
        </div>
    );
}
